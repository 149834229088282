<template>
  <div class="wrapper calendar">
    <el-row class="main">
      <aside class="menu-expanded" v-show="!collapsed">
        <!--导航菜单-->
        <div class="menu">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item title="" name="1">
              <template slot="title"
                ><div class="subTit">
                  <el-icon class="el-icon-files"></el-icon>快捷菜单
                </div></template
              >
              <ul class="menuList">
                <li
                  @click="searchCus(item.val, 'quick')"
                  v-for="item in menuList"
                  :class="actived == item.val ? 'actived' : ''"
                  :key="item.val"
                >
                  {{ item.name }}
                </li>
              </ul>
            </el-collapse-item>
            <el-collapse-item title="" name="2">
              <template slot="title"
                ><div class="subTit">
                  <el-icon class="el-icon-collection-tag"></el-icon>订阅搜索
                </div></template
              >
              <ul class="menuList">
                <li
                  @click="searchCus(item.id, 'order')"
                  v-for="item in OrderSearchList"
                  :class="actived == item.id ? 'actived' : ''"
                  :key="item.id"
                >
                  {{ item.name }}
                  <div class="closed" @click.stop="delSearch(item.id)">
                    &times;
                  </div>
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
        </div>
      </aside>
      <section
        class="content-container clearfix scollDom"
        :class="collapsed ? 'screen' : ''"
      >
        <el-col :span="24">
          <el-row class="searchBox">
            <el-form @submit.native.prevent :model="filter" ref="filter">
              <div class="fl searchL">
                <el-form-item label style="margin-bottom: 20px">
                  <el-input
                    class="search"
                    v-model="filter.filter"
                    @keyup.enter.native="submitForm('filter')"
                    placeholder="请输入任务对象/ 备注 /创建人/ 参与人，支持模糊搜索"
                  >
                    <el-button
                      type="primary"
                      slot="append"
                      @click="submitForm('filter')"
                      >搜 索</el-button
                    >
                  </el-input>
                </el-form-item>
              </div>
              <div class="other">
                <span class="order" @click="orderSearch">订阅搜索</span>
                <span class="more" @click="showMore = !showMore"
                  >{{ showMore ? "收起搜索" : "展开搜索"
                  }}<el-icon
                    :class="
                      showMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                    "
                  ></el-icon>
                </span>
              </div>
            </el-form>
            <div class="searchM" v-show="showMore">
              <el-form
                @submit.native.prevent
                :model="filter"
                ref="filter"
                class="clearfix"
                label-width="80px"
              >
                <el-form-item label="任务类型：" prop="scheduleType">
                  <el-select
                    v-model="filter.scheduleType"
                    placeholder="请选择"
                    style="width: 250px"
                    clearable
                    @change="submitForm('filter')"
                  >
                    <el-option
                      v-for="(item, index) in rwtypelist"
                      :key="index"
                      :value="item.val"
                      >{{ item.name }}</el-option
                    >
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="任务时间："
                  prop="time"
                  style="margin-left: 20px"
                >
                  <el-date-picker
                    v-model="rangTime"
                    type="daterange"
                    style="width: 250px"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    @change="submitForm('filter')"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="创建人：" prop="createUserId">
                  <el-select
                    v-model="filter.createUserId"
                    placeholder="请选择"
                    style="width: 250px"
                    filterable
                    clearable
                    @change="submitForm('filter')"
                  >
                    <el-option
                      v-for="i in userList"
                      :key="i.id"
                      :label="i.name"
                      :value="i.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
            <el-col class="table-top">
              <el-button size="mini" @click="addNew"> 新增日程 </el-button>
              <el-pagination
                :hide-on-single-page="filter.page.total > 10 ? false : true"
                v-if="filter.page.total > 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="filter.page.current"
                :page-size="filter.page.size"
                :pager-count="5"
                layout="total,prev, pager, next,sizes"
                :total="filter.page.total"
                :page-sizes="[10, 20, 50, 100]"
                style="padding-right: 0"
              ></el-pagination>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-table
                :header-cell-style="{
                  'background-color': '#f5f7fa',
                  height: '35px',
                  'border-top': '1px #e5e5e5 solid',
                }"
                :data="ListData"
                v-horizontal-scroll
                v-sticky="{ top: 0, parent: '.scollDom' }"
                class="tabBorder custor"
                v-loading="listLoading"
                ref="handSelectTest_multipleTable"
              >
                <el-table-column width="120" class-name="canEdit">
                  <template slot="header" slot-scope="scope">
                    任务类型
                  </template>
                  <template slot-scope="scope">
                    <span
                      class="tag"
                      :class="{
                        tag01: scope.row.scheduleType == '顾问面试',
                        tag06: scope.row.scheduleType == '客户面试',
                        tag21: scope.row.scheduleType == '候选人电话',
                        tag22: scope.row.scheduleType == '候选人背调',
                        tag16: scope.row.scheduleType == 'offert谈判',
                        tag17:
                          scope.row.scheduleType == '候选人入职' ||
                          scope.row.scheduleType == '候选人开票',
                        tag17: scope.row.scheduleType == '试用期结束',
                        tag04: scope.row.scheduleType == '客户电话',
                        tag19: scope.row.scheduleType == '客户拜访',
                        tag20: scope.row.scheduleType == '客户邮件',
                        tag18: scope.row.scheduleType == '其他',
                        tag26: scope.row.scheduleType == '联系候选人',
                        tag27: scope.row.scheduleType == '候选人面试',
                      }"
                      >{{ scope.row.scheduleType }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop
                  min-width="120"
                  align="left"
                  class-name="canEdit"
                  :show-overflow-tooltip="true"
                >
                  <template slot="header" slot-scope="scope">
                    任务对象
                  </template>
                  <template slot-scope="scope">
                    <span
                      @click="goOther(scope.row.moduleType, scope.row.targetId)"
                      class="tabHref"
                    >
                      {{ scope.row.targetName ? scope.row.targetName : "-" }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop
                  align="left"
                  class-name="canEdit"
                  min-width="120"
                  :show-overflow-tooltip="true"
                >
                  <template slot="header" slot-scope="scope"> 关联人 </template>
                  <template slot-scope="scope">
                    <span
                      v-if="
                        scope.row.moduleType == '项目' &&
                        scope.row.scheduleType !== '客户电话' &&
                        scope.row.scheduleType !== '客户拜访' &&
                        scope.row.scheduleType !== '客户邮件' &&
                        scope.row.relatePersonName
                      "
                      @click="goOther('人才', scope.row.relatePersonId)"
                      class="tabHref"
                    >
                      {{
                        scope.row.relatePersonName
                          ? scope.row.relatePersonName
                          : "-"
                      }}
                    </span>
                    <span v-else>
                      {{
                        scope.row.relatePersonName
                          ? scope.row.relatePersonName
                          : "-"
                      }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  :show-overflow-tooltip="true"
                  prop
                  align="left"
                  class-name="canEdit"
                  min-width="130"
                >
                  <template slot="header" slot-scope="scope"> 备注 </template>
                  <template slot-scope="scope">
                    {{ scope.row.comment ? scope.row.comment : "-" }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop=""
                  width="150"
                  align="left"
                  class-name="canEdit"
                >
                  <template slot="header" slot-scope="scope">
                    任务时间
                    <div
                      class="sortL"
                      :class="{
                        ascing: sortType == 'scheduleTime' && sortCheck == 1,
                        descing: sortType == 'scheduleTime' && sortCheck == 2,
                      }"
                    >
                      <i
                        class="asc"
                        @click.stop="sortWay('asc', 'scheduleTime')"
                      ></i>
                      <i
                        class="desc"
                        @click.stop="sortWay('desc', 'scheduleTime')"
                      ></i>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.scheduleTime ? scope.row.scheduleTime : "-" }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop
                  align="left"
                  class-name="canEdit"
                  width="120"
                >
                  <template slot="header" slot-scope="scope"> 创建人 </template>
                  <template slot-scope="scope">
                    {{ scope.row.createUserName }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop
                  align="left"
                  class-name="canEdit"
                  :show-overflow-tooltip="true"
                  min-width="120"
                >
                  <template slot="header" slot-scope="scope"> 参与人 </template>
                  <template slot-scope="scope"
                    >{{ scope.row.joinUserName ? scope.row.joinUserName : "-" }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="status"
                  align="left"
                  width="120"
                  class-name="canEdit"
                >
                  <template slot="header" slot-scope="scope">
                    任务状态
                  </template>
                  <template slot-scope="scope">
                    <div>
                      <div class="tableType">
                        {{ scope.row.scheduleStatus }}
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop
                  label="操作"
                  align="left"
                  width="150"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <span
                      type="text"
                      @click="handleEdit(scope.row.id)"
                      v-has="'setting:specialCom:edit'"
                      class="tabHref"
                      >编辑</span
                    >
                    <el-divider
                      v-if="scope.row.scheduleStatus == '未完成'"
                      direction="vertical"
                    ></el-divider>
                    <span
                      v-if="scope.row.scheduleStatus == '未完成'"
                      @click="
                        markerStatus(scope.row.id, scope.row.scheduleStatus)
                      "
                      v-has="'setting:specialCom:edit'"
                      class="tabHref"
                      >标记完成</span
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                :hide-on-single-page="filter.page.total > 10 ? false : true"
                v-if="filter.page.total > 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="filter.page.current"
                :page-size="filter.page.size"
                :pager-count="5"
                layout="total,prev, pager, next,sizes"
                :total="filter.page.total"
                :page-sizes="[10, 20, 50, 100]"
              ></el-pagination>
            </el-col>
          </el-row>
        </el-col>
      </section>
    </el-row>
    <!-- 弹框 -->
    <!-- modal-append-to-body -->
    <!-- 遮罩层是否插入至 body 元素上，若为 false，则遮罩层会插入至 Dialog 的父元素上 -->
    <!-- close-on-click-modal -->
    <!-- 是否可以通过点击 modal 关闭 Dialog -->
    <el-dialog
      :title="calendarTitle"
      :visible.sync="calendarVisible"
      :modal-append-to-body="false"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
      :before-close="caleCencel"
      destroy-on-close
    >
      <calenderFrom
        ref="calenderFrom"
        :formdata="calendardata"
        @close="caleCencel"
        @loadList="loadList"
      ></calenderFrom>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="caleCencel('calendardata')"
          >取 消</el-button
        >
        <el-button
          size="mini"
          :loading="saveloading"
          type="primary"
          @click="saveCalendar()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="orderSearchVisible"
      :modal-append-to-body="false"
      append-to-body
      title="订阅搜索"
      :close-on-click-modal="false"
      width="600px"
    >
      <el-form
        @submit.native.prevent
        :model="searchFrom"
        ref="searchFrom"
        style="padding: 0 50px"
        :rules="rules"
        :validate-on-rule-change="false"
        label-width="0"
        label-position="right"
      >
        <el-form-item prop="name">
          <el-input
            placeholder="请给订阅搜索取个名字"
            v-model.trim="searchFrom.name"
            maxlength="12"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            resetForm('searchFrom');
            orderSearchVisible = false;
          "
          size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="isSubscribeSearch"
          @click="submitOrder('searchFrom')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="proNameVisible"
      :modal-append-to-body="false"
      append-to-body
      title="编辑"
      :close-on-click-modal="false"
      width="600px"
      :before-close="closeDialog"
    >
      <el-form
        @submit.native.prevent
        :model="project"
        ref="project"
        :validate-on-rule-change="false"
        label-width="100px"
      >
        <p class="DialogTips">
          <i class="el-icon-warning"></i
          >项目名称是由‘职位+公司简称’自动生成的，您可以在此处修改职位名称
        </p>
        <el-form-item
          style="margin-bottom: 10px"
          prop="position"
          label="职位名称："
        >
          <el-input
            placeholder="请输入新的职位名称"
            v-model.trim="project.position"
            maxlength="50"
            @focus="showNewProject = true"
          ></el-input>
        </el-form-item>
        <el-form-item label="" style="margin: 0" v-if="showNewProject">
          <p style="font-size: 13px; color: #606266; line-height: 1">
            预览新项目名称：{{
              project.position + "-" + project.commonCompanyName
            }}
          </p>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            proNameVisible = false;
            showNewProject = false;
          "
          size="small"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="checkVal('project')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose1"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-rc_bt"></i>
        人才
      </div>
      <div class="drawerCon" style="padding: 0">
        <resumeDetails
          v-if="drawerVisible"
          :resumeDetailId="resumeId"
          :hover="hoverInfo"
          :repeatResume="repeatResume"
        ></resumeDetails>
      </div>
    </el-drawer>
    <el-drawer
      :visible.sync="customerDrawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-kh_xq"></i>客户
      </div>
      <div class="drawerCon">
        <customer
          :customId="customId"
          :activeToInfo="activedToInfo"
          @loadList="loadList"
        ></customer>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  getUserBaseInfos, //获取用户列表
  addOrEditProject, //编辑保存
  SearchAddOrEdit, //新增-编辑订阅管理
  GetSearchPage, //获取订阅管理列表
  GetSearchById, //根据ID获取方案
  deleteSearchById, //通过id删除方案
  permissionSecure, //是否有项目的查看权限
  schedulePage, //获取日程列表
  getScheduleStatusById, //标记状态
} from "../../api/api";
import { mapGetters } from "vuex";
import file from "../../components/file/file";
import addoreEdit from "./../../components/project/addorEdit";
import calenderFrom from "../../components/calendar/from";
import resumeDetails from "../../components/resumeDetail";
import customer from "../../components/customer/customerInfo";
import * as data from "../../common/json/commData.json";
export default {
  name: "calendar",
  components: {
    addoreEdit,
    file,
    calenderFrom,
    resumeDetails,
    customer,
  },
  data() {
    return {
      proActived: "2",
      proNameVisible: false,
      showNewProject: false,
      OrderSearchList: [],
      orderSearchVisible: false,
      isShowSaveBtn: false,
      favoriteList: {
        favoriteIds: [],
        itemIds: [],
      },
      isCheckbox: {
        val: true,
        edit: false,
      },
      searchFrom: {
        id: null,
        name: "",
        searchList: {},
        type: "日程",
        version: 0,
      },
      rules: {
        name: [
          { required: true, trigger: "blur", message: "请给订阅搜索取个名字" },
        ],
        moduleType: [
          {
            required: true,
            trigger: "change",
            message: "请选择对象",
          },
        ],
        scheduleType: [
          {
            required: true,
            trigger: "change",
            message: "请选择类型",
          },
        ],
        scheduleTime: [
          {
            required: true,
            trigger: "change",
            message: "请选择日期",
          },
        ],
        targetId: [
          {
            required: true,
            trigger: "change",
            message: "请选择任务对象",
          },
        ],
        // relatePersonId: [
        //   {
        //     trigger: "change",
        //     message: "请选择联系人",
        //   },
        // ],
        joinUserIds: [
          {
            required: true,
            trigger: "change",
            message: "请选择参与者",
          },
        ],
      },
      type: "日程",
      showMore: false,
      dialogVisible: false,
      specialItems: [],
      fuzzyItems: [],
      creditCode: "",
      fileManagement: false,
      activeNames: "1",
      activeNames1: ["1", "2"],
      shareData: [],
      myFileData: [],
      actived: "未完成",
      menuList: [
        {
          name: "未完成的日程",
          val: "未完成",
        },
        {
          name: "已完成的日程",
          val: "已完成",
        },
        {
          name: "所有日程",
          val: "all",
        },
      ],
      projectId: "", //项目id
      showSearchTab: false,
      // projectType: data.projectType,
      projectType: data.projectType1, //任务类型
      multipleSelection: [],
      listLoading: false,
      isDisableDel: true,
      delFiles: {
        favoriteId: "",
        itemIds: [],
      },
      filter: {
        filter: "",
        createUserId: "",
        scheduleEndTime: "",
        scheduleStartTime: "",
        ownProject: false,
        scheduleStatus: null,
        // userId: null,
        page: {
          asc: [],
          ascs: [],
          countId: "",
          desc: [],
          descs: [],
          hitCount: true,
          maxLimit: null,
          optimizeCountSql: true,
          orders: [
            {
              asc: false,
              column: "scheduleTime",
            },
          ],
          current: 1,
          size: 50,
          searchCount: true,
          pages: null,
          records: [],
          total: 0,
        },
      },
      project: {
        status: "",
        managerId: [],
        positionCompanyName: "",
      },
      isShowDelBtn: false,
      HsearchList: [],
      ListData: [],
      userList: [],
      sortCheck: null,
      sortType: "",
      props: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
      },
      showFavorBtn: false,
      rangTime: [],
      calendarTitle: "",
      calendarVisible: false,
      calendardata: {
        moduleType: "人才",
        comment: "",
        joinUserIds: [],
        scheduleType: null,
        scheduleTime: null,
        targetId: null,
        relatePersonId: null,
      },
      canClick: false,
      saveloading: false,
      missionObj: [], //任务对象
      missionObj1: [], //任务对象
      allChecked: [],
      isrequired: false,
      relatePersonList: [],
      calendaid: false,
      rwtypelist: [
        //客户类型
        {
          name: "顾问面试",
          val: "顾问面试",
        },
        {
          name: "客户面试",
          val: "客户面试",
        },
        {
          name: "候选人电话",
          val: "候选人电话",
        },
        {
          name: "候选人背调",
          val: "候选人背调",
        },
        {
          name: "offer谈判",
          val: "offer谈判",
        },
        {
          name: "候选人入职",
          val: "候选人入职",
        },
        {
          name: "候选人开票",
          val: "候选人开票",
        },
        {
          name: "试用期结束",
          val: "试用期结束",
        },
        {
          name: "客户电话",
          val: "客户电话",
        },
        {
          name: "客户拜访",
          val: "客户拜访",
        },
        {
          name: "客户邮件",
          val: "客户邮件",
        },
        {
          name: "联系候选人",
          val: "联系候选人",
        },
        {
          name: "候选人面试",
          val: "候选人面试",
        },
        {
          name: "其他",
          val: "其他",
        },
      ],
      selectloading: false,
      selectnoMore: false,
      targetId1: 0,
      calendarrwVisible: false,
      isSubscribeSearch: false,
      resumeId: "",
      drawerVisible: false,
      repeatResume: { type: "列表", valId: "" },
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      activedToInfo: "1",
      customerDrawerVisible: false,
      customId: "",
    };
  },
  computed: {
    ...mapGetters({
      collapsed: "upDateCollapsed",
    }),
  },
  created() {
    if (!this.$route.query.order && !this.$route.query.folder) {
      if (this.$route.query.ownProject) {
        //带参刷新
        this.filter.ownProject = eval(this.$route.query.ownProject);
        if (this.$route.query.status !== "all") {
          // this.filter.statusList.push(this.$route.query.status);
          this.filter.scheduleStatus = this.$route.query.status;
        }
        this.actived = this.$route.query.status;
      } else {
        //初始默认
        this.filter.ownProject = true;
        this.filter.scheduleStatus = "未完成";
      }
    } else {
      this.actived = this.$route.query.order || this.$route.query.folder;
      if (this.$route.query.order) {
        //订阅搜索
        GetSearchById(this.actived).then((res) => {
          if (res.success) {
            this.filter = res.result.searchList;
          }
        });
      }
    }

    if (this.$route.query.objectId) {
      //显示日程详情
      this.calendarVisible = true;
      this.calendarTitle = "日程";
      this.projectId = this.$route.query.objectId; //id
    }
    if (this.customerId) {
      this.calendardata.companyId = this.customerId;
      this.loadContactsLitst(this.customerId, "sign");
    }
    this.loadOrderSearchList();
    this.loadList();
    this.loadUserBase();
    // this.getpensonList();
  },
  watch: {
    "$route.query"(newValue, oldValue) {
      if (!newValue.order && !newValue.folder) {
        if (newValue.ownProject) {
          this.filter.ownProject = eval(newValue.ownProject);
          this.actived = newValue.status;
          if (newValue.status !== "all") {
            // this.filter.statusList.push(newValue.status);
            this.filter.scheduleStatus = newValue.status;
          }
        } else {
          this.actived = "未完成";
          this.filter.ownProject = true;
          // this.filter.statusList = ["未完成"];
          this.filter.scheduleStatus = null;
        }
        this.loadList();
      } else {
        //订阅&&文件夹
        this.actived = newValue.order || newValue.folder;
        if (newValue.order) {
          GetSearchById(this.actived).then((res) => {
            if (res.success) {
              this.filter = res.result.searchList;
              this.loadList();
            }
          });
        }
        if (newValue.folder) {
          //文件夹
          this.filter.favoriteId = this.actived;
          this.delFiles.favoriteId = this.actived;
          this.loadList();
        }
      }
    },
  },
  methods: {
    closeDialog() {
      this.proNameVisible = false;
      this.showNewProject = false;
    },
    loadOrderSearchList() {
      GetSearchPage({ type: "日程" }).then((res) => {
        if (res.success) {
          this.OrderSearchList = res.result;
        }
      });
    },
    submitOrder(formName) {
      this.isSubscribeSearch = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.searchFrom.searchList = this.filter;
          SearchAddOrEdit(this.searchFrom).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.orderSearchVisible = false;
              this.loadOrderSearchList();
              this.resetForm("searchFrom");
              this.isSubscribeSearch = false;
            }
          });
        } else {
          this.isSubscribeSearch = false;
          return false;
        }
      });
    },
    toEditInfo(id) {
      this.calendarTitle = "编辑日程";
      this.projectId = id;
    },
    tuComInfobyId(id) {
      this.calendarTitle = "日程";
    },
    detailsPop(id, type) {
      if (type) {
        //详情编辑保存
        this.calendarVisible = true;
        this.calendarTitle = "日程";
        this.proActived = "1";
        this.projectId = id; //id
      } else {
        permissionSecure({ id: id }).then((res) => {
          if (res.success) {
            if (res.result) {
              this.calendarVisible = true;
              this.calendarTitle = "日程";
              this.proActived = "2";
              this.projectId = id; //id
            } else {
              this.$message.error("暂无查看权限");
            }
          }
        });
      }
    },
    submitForm() {
      if (this.filter.id && this.filter.id < 1) {
        this.$message.error("请输入正确的id");
        return false;
      }
      if (this.rangTime && this.rangTime.length > 0) {
        this.filter.scheduleStartTime = this.rangTime[0];
        this.filter.scheduleEndTime = this.rangTime[1];
      } else {
        this.filter.scheduleStartTime = "";
        this.filter.scheduleEndTime = "";
      }
      this.filter.page.current = 1;
      this.loadList();
    },
    resetForm(formName) {
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });
    },
    cancel() {
      document.body.click();
    },
    //获取用户列表
    loadUserBase() {
      getUserBaseInfos(this.filter).then((res) => {
        if (res.success) {
          this.userList = res.result;
          this.allChecked = [];
          for (let i in this.userList) {
            this.allChecked.push(this.userList[i].id);
          }
        }
      });
    },
    delSearch(id) {
      //删除订阅搜索
      this.$confirm("确定删除该订阅?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          deleteSearchById(id).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadOrderSearchList();
              if (this.actived == id) {
                this.reset();
                this.$router.push({
                  path: "/calendar",
                  query: {
                    ownProject: true,
                    status: "未完成",
                  },
                });
                this.loadList();
              }
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleCloseResult() {
      this.dialogVisible = false;
    },
    //关闭日程弹窗
    handleClose(done) {
      if (this.calendarTitle == "编辑日程") {
        this.calendarTitle = "日程";
        this.calendarVisible = false;
      } else {
        this.calendarTitle = "";
        this.calendarVisible = false;
      }
      this.customerDrawerVisible = false;
    },
    orderSearch() {
      //订阅搜索
      this.orderSearchVisible = true;
    },
    sortWay(name, type) {
      //升降序
      this.sortType = type;
      this.sortCheck = null;
      this.filter.page.orders[0].column = type;
      if (name == "asc") {
        this.sortCheck = this.sortCheck == 1 ? null : 1;
        this.filter.page.orders[0].asc = true;
      }
      if (name == "desc") {
        this.sortCheck = this.sortCheck == 2 ? null : 2;
        this.filter.page.orders[0].asc = false;
      }
      if (this.sortCheck == null) {
        this.filter.page.orders[0].asc = "";
        this.filter.page.orders[0].asc = "";
        this.filter.page.orders[0].column = "";
      }
      this.loadList();
    },
    checkVal() {
      //修改内容
      if (this.project.positionCompanyName == "") {
        this.$message.error("修改内容不能为空");
        return false;
      }
      addOrEditProject(this.project).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          document.body.click();
          this.loadList();
          this.proNameVisible = false;
        }
      });
    },

    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.filter.page.size = val;
      this.filter.page.current = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      // this.filter.page.pages = val;
      this.filter.page.current = val;
      this.loadList();
    },
    handleChange() {},
    reset() {
      this.filter = {
        filter: "",
        createUserId: "",
        scheduleEndTime: "",
        scheduleStartTime: "",
        scheduleStatus: null,
        ownProject: false,
        page: {
          asc: [],
          ascs: [],
          countId: "",
          desc: [],
          descs: [],
          hitCount: true,
          maxLimit: null,
          optimizeCountSql: true,
          orders: [
            {
              asc: false,
              column: "scheduleTime",
            },
          ],
          current: 1,
          size: 50,
          searchCount: true,
          pages: null,
          records: [],
          total: 0,
          resumeId: "",
        },
      };
    },
    searchCus(val, type) {
      if (this.actived != val) {
        this.actived = val;
        this.sortType = "";
        this.sortCheck = null;
        switch (type) {
          case "quick": //快捷菜单
            this.reset();
            this.$router.push({
              path: "/calendar",
              query: {
                ownProject: val != "all" ? true : false,
                status: val,
              },
            });
            break;
          case "order":
            this.$router.push({
              path: "/calendar",
              query: {
                order: val,
              },
            });
            break;
          case "folder":
            this.$router.push({
              path: "/calendar",
              query: {
                folder: val,
              },
            });

            break;
        }
      }
    },
    //提交
    save(formName) {
      this.$refs[formName].validate((valid) => {
        this.calendardata.comment = this.calendardata.comment
          ? this.calendardata.comment
          : "";
        if (valid) {
          if (this.upload_flag) {
            this.$refs.upload.submit();
          } else {
            // this.saveInfo();
          }
        } else {
          return false;
        }
      });
    },
    //保存新增日程
    saveCalendar() {
      // this.saveloading = true;
      this.$refs.calenderFrom.saveInfo();
    },
    //日程列表
    loadList() {
      window.scrollTo(0, 0);
      this.listLoading = true;
      if (this.calendarTitle != "日程") {
        this.calendarVisible = false;
      }
      schedulePage(this.filter).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          if (this.filter.favoriteId && this.ListData.length) {
            this.isShowDelBtn = true;
          }
          this.ListData.forEach((element) => {
            element["show"] = false;
          });
          this.filter.page.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    //标记状态
    markerStatus(id, scheduleStatus) {
      // let params = JSON.stringify({
      //   id: id,
      //   scheduleStatus: scheduleStatus,
      // });
      const params = new FormData();
      params.append("id", id);
      params.append("scheduleStatus", "已完成");
      getScheduleStatusById(params).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.loadList();
        } else {
          // this.upload_flag = false;
        }
        // this.saveloading = false;
      });
    },
    //新增日程
    addNew() {
      this.calendaid = false;
      this.calendarVisible = true;
      this.calendarTitle = "新增日程";
      this.canClick = false;
      // this.resetForm("calendardata");
      this.calendardata.moduleType = "人才";
      this.calendardata.comment = "";
      this.calendardata.joinUserIds = [];
      this.calendardata.scheduleType = "联系候选人";
      this.calendardata.scheduleTime = "";
      this.calendardata.targetId = null;
      this.calendardata.relatePersonId = null;
      this.calendardata.id = null;
      this.targetId1 = 0;
      this.calendarrwVisible = true;
      this.$nextTick(() => {
        this.$refs.calenderFrom.getpensonList();
      });
      // this.calendardata.scheduleType = "联系候选人";
    },
    //编辑日程
    handleEdit(id) {
      this.calendaid = id ? true : false;
      this.calendardata.id = id;
      this.calendarVisible = true;
      this.upload_flag = false;
      this.calendarTitle = "编辑日程";
      this.canClick = true;
      this.$nextTick(() => {
        this.$refs.calenderFrom.handleEdit(id);
      });
    },
    //取消关闭日程弹窗
    caleCencel(val) {
      // this.resetForm("calendardata");
      this.calendarVisible = false;
      this.calendardata.moduleType = "人才";
      this.calendardata.comment = "";
      this.calendardata.joinUserIds = [];
      this.calendardata.scheduleType = "联系候选人";
      this.calendardata.scheduleTime = "";
      this.calendardata.targetId = null;
      this.calendardata.relatePersonId = null;
      this.calendardata.id = null;
      this.targetId1 = 0;
      this.calendarrwVisible = false;
    },
    handleClose1() {
      this.resumeId = "";
      this.drawerVisible = false;
    },
    //跳转
    goOther(type, id) {
      if (type === "人才") {
        this.resumeId = id;
        this.drawerVisible = true;
      } else if (type === "客户") {
        this.customerDrawerVisible = true;
        this.customId = id;
      } else if (type === "项目") {
        this.$router.push({
          path: "/project/details",
          query: {
            id: id,
          },
        });
      }
    },
    //任务对象
    getTargetId(data) {
      this.calendardata.targetId = data;
      // this.changeTatget(data)
    },
    //
    cleantargetId1() {
      this.targetId1 = 0;
    },
  },
  destroyed() {
    this.canScroll();
  },
  mounted() {
    this.noScroll(); //禁止滚动
  },
};
</script>
<style  lang="scss">
.el-tooltip__popper.is-dark,
.el-tooltip__popper.is-light {
  max-width: 300px;
}
.calendar {
  .el-table .cell {
    padding: 0 15px;
  }
}
</style>
<style lang="scss" scoped>
@import "../collapsed.less";
.searchM {
  margin-bottom: 5px;
  .el-form-item {
    float: left;
  }
}
.searchL {
  width: calc(100% - 162px);
}
.search {
  height: 35px;
  line-height: 35px;
  :deep(.el-input__inner) {
    height: 35px !important;
    line-height: 35px !important;
    border-color: #e60012 !important;
  }
  :deep(.el-input-group__append) {
    border-color: #e60012 !important;
    background-color: #e60012;
    height: 33px !important;
    line-height: 33px !important;
    color: #fff;
    font-weight: bold;
  }
  :deep(.el-input-group__append:hover) {
    background-color: #cc0000;
  }
}
.other {
  line-height: 35px;
  font-size: 13px;
  float: right;
  span {
    margin: 0;
    cursor: pointer;
    color: #606266;
    &:hover {
      color: #e60012;
    }
  }
  .order {
    padding: 0 8px;
    border-right: 1px solid #e5e5e5;
  }
  .more {
    padding-left: 8px;
    i {
      margin-left: 8px;
    }
  }
}
.tableType {
  display: inline-block;
}
</style>